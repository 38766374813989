const config = {
        hideBooking: true,
        hideProductAndDocuments: true,
        hideProduct: true,
        hideOtp: true,
        hideOcr: true,
        hideChooseDeviceBtn: true
};

const env = [
        {key: 'hideBooking', value: process.env.VUE_APP_HIDE_BOOKING, default: true},
        {key: 'hideProductAndDocuments', value: process.env.VUE_APP_HIDE_DOC_BLOCK, default: false},
        {key: 'hideProduct', value: process.env.VUE_APP_HIDE_PRODUCT, default: true},
        {key: 'hideOtp', value: process.env.VUE_APP_HIDE_OTP, default: true},
        {key: 'hideOcr', value: process.env.VUE_APP_HIDE_OCR, default: true},
        {key: 'hideChooseDeviceBtn', value: process.env.VUE_APP_HIDE_CHOOSE_DEVICE_BTN, default: true},
]

env.forEach((param) => {
        let value = param.default;
        if(typeof param.value !== undefined && typeof param.value === "string") {
                if(param.value === 'false') value = false;
                if(param.value === 'true') value = true;
        }
        config[param.key] = value;
})

module.exports = config;
